




















import { Component, Vue } from 'vue-property-decorator'
import VsContainer from '@/components/VsContainer/Index.vue'
import VsSectionHeader from '@/components/VsSectionHeader/Index.vue'
import { UserModule } from '@/store/modules/user'

@Component({
    name: 'MockIndex',
    components: {
        VsContainer,
        VsSectionHeader,
    },
})
export default class extends Vue {
    childId = ''
    childs: any[] = []

    get user () {
        return UserModule.user
    }

    get isImpersonate () {
        return this.user.impersonate
    }
}
